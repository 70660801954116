<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card>
      <v-card-title>
        <back-button color="grey" @click="dialog = false" />
        Cancelar agendamento
      </v-card-title>
      <v-card-text>
        <v-form ref="form">
          <v-textarea
            v-model="reason"
            label="Motivo do cancelamento"
            maxlength="500"
            counter
            outlined
            :rules="[$rules.required]"
          />
          <v-btn block color="secondary" @click="cancel"> Enviar </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { cancelAppointment } from "@/services/external/appointments-service";

export default {
  name: "CancellationDialog",
  data: () => ({
    reason: "",
    dialog: false,
    appointmentId: 0,
    source: "",
  }),
  methods: {
    open({ appointmentId, source }) {
      this.appointmentId = appointmentId;
      this.source = source;
      this.dialog = true;
    },
    async cancel() {
      try {
        if (!this.$refs.form.validate()) return;
        this.$store.dispatch("loading/openDialog");
        const {
          data: { appointmentId, newStatus },
        } = await cancelAppointment(this.appointmentId, {
          reason: this.reason,
          source: this.source,
        });
        this.$toasted.global.info({
          message: "Agendamento cancelado. O paciente foi notificado.",
        });
        this.$emit("submit", {
          appointmentId,
          newStatus,
          reason: this.reason,
          source: this.source,
        });
        this.dialog = false;
      } catch (e) {
        this.$errorHandler(e);
      } finally {
        this.$store.dispatch("loading/closeDialog");
      }
    },
  },
};
</script>

<style scoped></style>
